import React, { useMemo } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import _ from 'lodash';
import Vbox from '../Vbox/Vbox';
import { previewSelector } from '../../../modules/selectors/standort/preview';
import { fetchPreviewIfNeeded } from '../../../modules/actions/standort/preview';

const CMSTeaser = ({ h2, h3, previewCategory, preview }) => {
    const teaser = useMemo(() => {
        return _.filter(preview?.teaser, item => item.category === previewCategory);
    }, [preview, previewCategory]);

    if (!teaser) return null;
    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                {h2 && <h2 className="uk-text-light">{h2}</h2>}
                {h3 && <h3>{h3}</h3>}
                <div className="uk-grid uk-grid-small" data-uk-grid="">
                    {teaser.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={classnames({
                                    'uk-width-1-2 uk-width-1-3@m': item.size === 's',
                                    'uk-width-1-1 uk-width-1-2@s': item.size === 'm',
                                    'uk-width-1-1 uk-width-2-3@m': item.size === 'l',
                                })}
                            >
                                <Vbox item={item} type="teaser" />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

const frontload = async props => await Promise.all([props.fetchPreviewIfNeeded({ previewCategories: [props.previewCategory] })]);
const mapStateToProps = (state, props) => ({ preview: previewSelector(state, { previewCategories: [props.previewCategory] }) });
const mapDispatchToProps = { fetchPreviewIfNeeded };

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(CMSTeaser));
